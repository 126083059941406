import React from "react"
import "./main-view.style.scss"
import { MainSlider } from "../../components/slider"
import { ProductList } from "../../components"
import MtsImage from "../../assets/img/MTS.webp"

const PhoneNumber = () => (
  <a
    style={{
      color: "inherit",
      textDecoration: "inherit",
    }}
    href="tel:+375297490117"
  >
    +375 29 749 01 17
  </a>
)

export class MainView extends React.Component {
  render() {
    return (
      <div className="main-view">
        <header className="ai-header">
          <div className="ai-header-logo">
            <i
              className="ai-header-logo-icon fa fa-wrench"
              aria-hidden="true"
            ></i>
            ИП "Каменев А.В."
          </div>
          <div className="ai-header-contacts-container">
            <h2 className="ai-header-pnumber">
              <PhoneNumber></PhoneNumber>
              <img className="ai-header-img" src={MtsImage} />
            </h2>
            <div className="ai-header-working-hours-container">
              <h4 className="ai-header-working-title">Режим работы:</h4>
              <div>
                <h4 className="ai-header-working-hours">9:00 - 18:00 ПН-ПТ</h4>
                <h4 className="ai-header-working-hours">9:00 - 14:00 CБ-ВС</h4>
              </div>
            </div>
          </div>
        </header>
        <div className="ai-header__slogan">
          <h1>Ремонт автомобилей и микроавтобусов</h1>
        </div>
        <div className="ai-main-frame">
          <MainSlider />
        </div>
        <h1 className="ai-products__title">НАШИ ПРЕИМУЩЕСТВА</h1>
        <div className="ai-advantages">
          <div className="ai-advantages-item">
            <i className="fas fa-shuttle-van ai-icon fa-5x"></i>
            <h3>Ремонт всех марок</h3>
          </div>
          <div className="ai-advantages-item">
            <i className="fas fa-briefcase ai-icon fa-5x"></i>
            <h3>Индивидуальный подход</h3>
          </div>
          <div className="ai-advantages-item">
            <i className="fas fa-handshake ai-icon fa-5x"></i>
            <h3>Гарантия качества</h3>
          </div>
          <div className="ai-advantages-item">
            <i className="fas fa-cart-arrow-down ai-icon fa-5x"></i>
            <h3>Низкие цены</h3>
          </div>
        </div>

        <h1 className="ai-products__title">НАШИ УСЛУГИ</h1>
        <div className="ai-products" id="products">
          <ProductList />
        </div>
        <h1 className="ai-products__title">КОНТАКТЫ</h1>
        <div className="ai-about">
          <div className="ai-about-item__map">
            <iframe
              src="https://yandex.by/map-widget/v1/-/CCQlnLu5OD"
              className="ai-about-item__map-frame"
              frameBorder="0"
              allowfullscreen
            ></iframe>
          </div>
          <div className="ai-about-item">
            <div className="ai-about-item-container">
              <h1>Как нас найти</h1>
              <p className="ai-about-text__primary">
                улица Лазаренко, 55Ак4, Могилёв
              </p>
              <p className="ai-about-text__primary">
                Телефон <PhoneNumber></PhoneNumber> (МТС)
              </p>
              <div className="ai-about-text__primary">
                Группа в
                <a
                  className="ai-sn"
                  target="_blank"
                  href="https://vk.com/remont_auto_mogilev"
                >
                  <i className="fab fa-vk ai-sn-icon fa-1x ai-sn__icon"></i>
                </a>
              </div>
              <p className="ai-about-text__primary">
                <a
                  className="ai-sn"
                  target="_blank"
                  href="https://www.avservice.by/autoservice/avtoservis-ip-kamenev?utm_source=avservice.by&utm_medium=backlink&utm_campaign=autoservice_3468"
                >
                  Мы на AVservice.by
                </a>
              </p>
              <div className="ai-about-working-hours-container ai-about-text__primary">
                <h4 className="ai-about-working-title">Режим работы:</h4>
                <div>
                  <h4 className="ai-about-working-hours">9:00 - 18:00 ПН-ПТ</h4>
                  <h4 className="ai-about-working-hours">9:00 - 14:00 CБ-ВС</h4>
                </div>
              </div>
              <p className="ai-about-text__primary">
                УНП 791108872, ИП "Каменев А.В."
              </p>
            </div>
          </div>
        </div>
        <div className="ai-sign">
          <div className="ai-sign__container">
            <a href="https://swegroup.web.app/" target="_blank">
              <span className="ai-sign__label">Разработчик</span>
              <span className="ai-sign__logo">SWEG</span>
            </a>
          </div>
        </div>
      </div>
    )
  }
}

import React from "react";
import { ProductItem } from "../product-item/product-item.jsx";
import { SERVICES_CONFIG } from "./product-list.const";
import "./product-list.style.scss";

export class ProductList extends React.Component {
  render() {
    return (
      <div className="ai-product-list">
        {SERVICES_CONFIG.map((item, index) => {
          return (
            <ProductItem
              pic={item.pic}
              title={item.title}
              subproducts={item.subproducts}
              key={index}
            />
          );
        })}
      </div>
    );
  }
}

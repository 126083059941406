import comp_diagnosticsjpg from "../../assets/img/prodpics/comp_diagnostics.webp";
import coolerjpg from "../../assets/img/prodpics/cooler.webp";
import chainsjpg from "../../assets/img/prodpics/chains.webp";
import fix_enginejpg from "../../assets/img/prodpics/fix_engine.webp";
import fix_tormjpg from "../../assets/img/prodpics/fix_torm.webp";
import pnevmojpg from "../../assets/img/prodpics/pnevmo.webp";
import zapchastijpg from "../../assets/img/prodpics/zapchasti.webp";
import oiljpg from "../../assets/img/prodpics/oil.webp";
import sczepleniejpg from "../../assets/img/prodpics/sczeplenie.webp";
import salonjpg from "../../assets/img/prodpics/salon.webp";
import rylevoejpg from "../../assets/img/prodpics/rylevoe.webp";
import pnevmo1jpg from "../../assets/img/prodpics/pnevmo1.webp";
import dopjpg from "../../assets/img/prodpics/dop.webp";
import akppjpg from "../../assets/img/prodpics/akpp.webp";

export const SERVICES_CONFIG = [
  {
    pic: comp_diagnosticsjpg,
    subproducts: [{ name: "Проверка двигателя и других электронных систем" }],
    title: "Компьютерная диагностика",
  },
  {
    pic: coolerjpg,
    subproducts: [
      { name: "Замена помпы" },
      { name: "Замена термостата" },
      { name: "Замена радиатора" },
      { name: "Замена антифриза" },
      { name: "Замена электро и виско-вентиляторов " },
    ],
    title: "Ремонт системы охлаждения",
  },
  {
    pic: chainsjpg,
    subproducts: [
      { name: "Замена цепей ГРМ" },
      { name: "Замена ремней ГРМ" },
      { name: "Замена шестерён и звёзд ГРМ" },
      { name: "Замена натяжителей ГРМ" },
    ],
    title: "Замена цепей и ремней ГРМ на любых моторах (R4, V6, V8, W12)",
  },
  {
    pic: fix_enginejpg,
    subproducts: [
      { name: "Замена прокладок ГБЦ" },
      { name: "Замена распредвала" },
      { name: "Замена коленвала" },
      { name: "Замена поршневой" },
      { name: "Замена маслосьёмных колпачков" },
    ],
    title: "Ремонт двигателей",
  },
  {
    pic: fix_tormjpg,
    subproducts: [
      { name: "Замена тормозных дисков и колодок" },
      { name: "Замена тормозных цилиндров и шлангов" },
      { name: "Замена суппортов" },
    ],
    title: "Ремонт тормозных систем",
  },
  {
    pic: pnevmojpg,
    subproducts: [
      { name: "Замена ступичных подшипников" },
      { name: "Замена сайлентблоков" },
      { name: "Замена шрусов и пыльников" },
      { name: "Замена амортизаторов" },
      { name: "Замена стабилизаторов" },
    ],
    title: "Ремонт подвески",
  },
  {
    pic: zapchastijpg,
    title: "Б/У запчасти (ДВС, АКПП, МКПП и т.д.) в наличии и под заказ",
  },
  { pic: oiljpg, title: "Замена масла в ДВС и АКПП" },
  {
    pic: sczepleniejpg,
    subproducts: [
      { name: "Замена дисков сцепления" },
      { name: "Замена маховика" },
      { name: "Замена выжимных подшипников" },
    ],
    title: "Замена сцепления МКПП",
  },
  {
    pic: salonjpg,
    subproducts: [
      { name: "Замена радиатора печки" },
      { name: "Замена заслонок печки" },
      { name: "Замена вентилятора салона" },
    ],
    title: "Ремонт системы отопления салона",
  },
  {
    pic: rylevoejpg,
    subproducts: [
      { name: "Замена насоса ТУР" },
      { name: "Замена шаровых наконечников и рулевых тяг" },
      { name: "Ремонт и замена рулевых реек" },
    ],
    title: "Ремонт рулевого управления",
  },
  {
    pic: pnevmo1jpg,
    subproducts: [
      { name: "Замена аммортизаторов" },
      { name: "Восстановление пневмобаллонов" },
      { name: "Замена клапанов" },
      { name: "Замена датчиков уровня подвески" },
    ],
    title: "Ремонт пневмоподвески",
  },
  {
    pic: dopjpg,
    subproducts: [
      { name: "Заправка кондиционеров" },
      { name: "Сварка трубок аргоном" },
    ],
    title: "Дополнительные услуги",
  },
  {
    pic: akppjpg,
    title: "Замена АКПП, ДВС узлов агрегатов на л/а и м/а",
  },
];

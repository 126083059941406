import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import App from "../web/app"

const IndexPage = () => (
  <Layout>
    <SEO title="Автосервис ИП Каменев А. В." />
    <App></App>
  </Layout>
)

export default IndexPage
